<template>
  <div id="statisticsdata">
    <div class="hearder">
      <el-row>
        <el-col :span="12">分组名称：{{ getGroupInfo.gname }}</el-col>
        <el-col :span="12">学员数：{{ getGroupInfo.number }}</el-col>
      </el-row>
      <el-row class="mt20">
        <el-col :span="12">
          创建时间：{{
            getGroupInfo.create_time | formatTimeStamp('yyyy-MM-dd hh:mm')
          }}
        </el-col>
        <el-col :span="12">创建者：{{ getGroupInfo.cname }}</el-col>
      </el-row>
    </div>
    <div class="main">
      <learningstatistics></learningstatistics>
    </div>
  </div>
</template>

<script>
import learningstatistics from '../detail/learningstatistics'
export default {
  data() {
    return {
      getGroupInfo: {},
    }
  },
  components: {
    learningstatistics,
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$http({
        url: '/pcUser/getGroupInfo',
        data: {
          gid: this.$route.query.gid,
        },
        callback: ({ data }) => {
          this.getGroupInfo = data
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
#statisticsdata {
  padding: 20px;
  .hearder {
    height: 85px;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;
    ::v-deep .el-col {
      font-size: 12px;
      color: #333333;
      line-height: 12px;
    }
  }
  .main {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
  }
}
</style>